@import "src/styles/variables";

.dashboard {
  .app-header {

    display: flex;
    align-items: center;

    .app-header__title {
      font: normal normal bold 18px/24px Nunito;
      letter-spacing: 0px;
      color: $secondary-color;
    }
  }

  .graph-container {
    height: 300px;
    margin-top: 80px;

    .graph-title {
      margin-bottom: 40px;
      font: normal normal bold 15px/24px Nunito;
      letter-spacing: 0px;
      color: $secondary-color;
    }
  }
}