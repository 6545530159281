@import "variables";
@import "helpers";
@import "icons";
@import "antOverride";

/* General component styles */
body {
  background-color: #FFFFFF;
  color: $primary-black;
}

body,
h1,
h2,
h3,
h4,
h5,
button,
input {
  font-family: 'Nunito', sans-serif !important;
}

.app-wrapper {
  padding: 3rem 0 0 20%;
  width: 95%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Checkbox as Radio */
.checkbox-as-radio {
  .ant-checkbox-inner {
    border-radius: 50px;
  }

  .ant-checkbox:hover::after,
  .ant-checkbox-wrapper:hover .ant-checkbox::after {
    border: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: $primary-color;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #f5f5f5;
  }

  &.disabled {
    .ant-checkbox-wrapper {
      pointer-events: none;
    }

    .ant-checkbox-inner {
      opacity: 0.45;
      border: 1px solid #707070;
    }
  }
}