@import "src/styles/variables";

.permission-details {

    height: 80vh;
    box-shadow: 0px 10px 20px #AEAEAE29;
    border-radius: 8px;
    padding: 20px;
    overflow: scroll;

    .role-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 90%;
        background: white;
        z-index: 10;
        padding: 20px 0px;
        top: 0;

        i {
            padding: 6px 8px;
            border-radius: 4px;
            background-color: $icon-background-color;
            margin: 0px 4px;
            cursor: pointer;
        }

        span {
            font: normal normal 600 16px/8px Nunito;
            cursor: pointer;

            &.text-secondary {
                color: #ADADAD;
            }
        }


        .input-field {
            width: 50%;

            .ant-input {
                border-radius: 4px;
                height: 38px;
            }
        }

        div {
            font: normal normal normal 15px/9px Nunito;
            color: $primary-color;
        }
    }

    .permission-header {
        padding-top: 60px;

        div {
            font: normal normal 600 14px/18px Nunito;
            color: $tag-color;
        }
    }

    .ant-row span {
        font: normal normal normal 14px/19px Nunito;
    }

    .top-spacing {
        margin-top: 40px;
    }

}