@import "src/styles/variables";

.app-sidebar {
  position: fixed;
  width: 17%;
  height: 100vh;
  background-color: #ffffff;
  box-shadow: $primary-shadow;
  border-radius: 8px;
  z-index: 1;

  .app-sidebar__container {
    height: inherit;
    position: relative;
    padding: 0 1rem 1rem;
  }


  .app-sidebar__menubar {
    width: 90%;
    margin: auto;
    position: relative;
  }

  .app-sidebar__menubar__logo {
    text-align: center;
    margin: 24px 0 45px 0;

    img {
      width: 75%;
    }
  }

  .app-sidebar__menubar.ant-menu:not(.ant-menu-horizontal) {
    position: relative;
    height: calc(100vh - 10rem);
    border: none;
    padding-bottom: 200px;
    overflow: scroll;

    .ant-menu-item {
      border-radius: 8px;
      padding: 5px;
      height: auto;
      margin: 0 0 0.2rem;

      &:hover {
        color: $primary-color;
      }

      &.disabled,
      &.disabled.ant-menu-item-selected {
        opacity: .4;
        cursor: not-allowed;
        // pointer-events: none;
        background: white;
        color: inherit;
      }

      &.ant-menu-item-selected {
        position: relative;
        background: $primary-gradient;
        border-radius: 8px;
        color: #ffffff;

        &:after {
          border: none;
        }

        .active-icon::before {
          color: #FFFFFF !important;
        }

      }

      .anticon-tag{
        margin-right: 0;
      }
      
    }
  }

  .app-sidebar__menu-title {
    display: inline-block;
    margin-left: 10px;
  }

  .app-sidebar__profile-container {
    position: absolute;
    bottom: 1.5rem;
    right: 0;
    left: 0;
    background: white;
    padding: 0 1rem 10px;

    .app-sidebar__profile {
      background-color: $sidebar-profile-bg;
      padding: 1rem !important;
      border-radius: 10px;
    }
  }

  .app-sidebar__profile {
    text-align: center;

    .app-sidebar__profile-img {
      width: 80px;
      height: 70px;
      border-radius: 50%;
    }

    .app-sidebar__profile-info {
      .app-sidebar__username {
        margin: 10px 0 0 0;
        line-height: 1rem;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

  .app-badge {
    background-color: #406481;
    border-radius: 12px;
    font-size: 10px;
    color: #FFFFFF;
    padding: 5px 10px;
  }
}

.ant-popover-placement-right {
  padding: 15px 20px;

  .ant-popover-inner {
    border-radius: 8px;
  }
}

.profile-menu__item {
  margin: 10px 0;
  cursor: pointer;

  span {
    margin-left: 10px;
  }
}