@import "src/styles/variables";

.app-header {
  margin-bottom: 35px;

  .app-header__left,.app-header__right{
    display: inline-block;
    width: 50%;
  }

  .app-header__left{
    .go-back{
      cursor: pointer;
      display: inline-block;
      vertical-align: top;
      .anticon{
        color: $secondary-color !important;
        font-weight: 800;
      }
    }

    .image-icon{
      width: 20px;
      margin-right: 10px;
      margin-bottom: 8px
    }
  }

  .app-header__right{
    text-align: right;
    vertical-align: baseline;
  }

  .app-header__icon,.app-header__title,.app-header__search,.app-header__action{
    display: inline-block;
  }

  .app-header__icon{
    font-size: 18px;
    margin-right: 10px;
    &:before{
     color: $secondary-color;
    }
  }

  .app-header__title{
    color: $secondary-color;
    font-size: 16px;
    font-weight: 700;
    vertical-align: text-bottom;
  }

  .app-header__search{
    vertical-align: middle;
    /*.ant-input-search{
      width: 275px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 12px #0000000A;
      border-radius: 8px;
      margin-right: 25px;
    }
    .ant-input-wrapper{
      padding: 0 10px;
    }
    .ant-input-affix-wrapper,.ant-input-group-addon .ant-btn{
      border: unset !important;
    }
    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
      box-shadow: unset !important;
    }*/
  }

  .app-header__action{
    .app-header__button{
      height: 42px;
      border-radius: 8px;
      font-size: 14px;
      background: $primary-header-button-gradient !important;
    }
  }
}