@import "src/styles/variables";

.dashboard-card {
  display: flex;
  align-items: center;
  padding: 30px;
  justify-content: center;
  box-shadow: 0px 10px 20px #AEAEAE29;
  border-radius: 4px;
  cursor: pointer;

  .icon-conianter {
    position: relative;
    margin-bottom: 18px;
    margin-right: 10px;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.purple {
      background-color: #F8F2FB;
    }

    &.blue {
      background-color: #F2F5FB;
    }

    &.green {
      background-color: #F2FBF6;
    }

    &.yellow {
      background-color: #FBF8F2;
    }

    img{
      width: 40%;
    }
  }

  .stats-count {
    font: normal normal bold 32px Nunito;
    color: #000000;
  }

  .stats-name {
    font: normal normal normal 16px Nunito;
    color: #646464;
  }
}