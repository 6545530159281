@import "src/styles/variables";

.add-permission-form {
    .permission-form-header {
        font: normal normal bold 18px/8px Nunito;
        color: $secondary-color;
    }

    .top-spacing-30 {
        margin-top: 30px;
    }

    .top-spacing {
        margin-top: 40px;
    }

    .action-buttons {
        text-align: right;

        button {
            margin-left: 20px;
            height: 52px !important;
            font-size: 16px !important;
        }
    }
}