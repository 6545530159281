@import "src/styles/variables";

.role-list {
    padding: 10px;
    padding-left: 0px;

    .role-list-sidebar-container {
        height: 80vh;
        overflow: auto;
        background: #FFFFFF 0 0 no-repeat padding-box;
        box-shadow: 0 10px 20px rgba(174, 174, 174, 0.1607843137);
        padding: 30px 20px;
        border-radius: 8px;
        position: relative;

        .role-sidebar__title {
            position: relative;

            .role-sidebar__active-background {
                display: block;
                z-index: 1;
                background: $primary-password-reset-color;
                border-radius: 4px;
                height: 40px;
                position: absolute;
                top: 0;
                left: 5%;
                width: 0;

                &.active {
                    width: 90%;
                }
            }

            .role-title {
                position: relative;
                color: #646464;
                font-size: 15px;
                padding: 10px 20px;
                width: 90%;
                margin-left: 5%;
                cursor: pointer;
                text-align: left;
                z-index: 2;

                &.active {
                    color: #FFFFFF;
                    background: $primary-password-reset-color;

                    span {
                        font: normal normal bold 16px/8px Nunito;
                        color: #FFFFFF;
                    }
                }

                span {
                    display: inline;
                    vertical-align: middle;
                }
            }

            .ant-divider {
                width: 90%;
                min-width: unset;
                margin: 15px auto;
            }

            &:hover:not(.add-new) {
                .role-sidebar__active-background {
                    width: 90%;
                    animation: animateWidth 350ms ease-in;
                }

                .role-title {
                    background: $primary-password-reset-color;
                    color: #FFFFFF;

                    span {
                        color: #FFFFFF;
                    }
                }
            }

            &.add-new {
                .role-title {
                    font-size: 18px;
                    color: #235663;
                    margin-top: 10px;
                    font-weight: 700;
                    cursor: pointer;
                }
            }

            span {
                font: normal normal normal 16px/8px Nunito;
                color: $tag-color;
            }
        }
    }
}

@keyframes animateWidth {
    0% {
        width: 0;
    }

    100% {
        width: 90%;
    }
}